import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import { useDispatch } from "react-redux";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function UserManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllUsers(currentPage, limit, search);
      console.log(response.data.data);
      setUsers(response.data.data);
      const total = response.data.totalRecords;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.totalRecords);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  ///function to delete data ///
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteUser(id);
      getTableData();
      notifyTopRight("Deleted Successfully!");
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id, actionType) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      actionType === true
        ? notifyTopRight("Disabled Successfully.")
        : notifyTopRight("Enabled Successfully.");

      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "heloo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  let timeoutId;

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <PageTitle activeMenu="Company List" motherMenu="Company" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0 search-input"
                      placeholder="Search"
                 
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>company id</strong>
                    </th>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>
                    <th>
                      <strong>user type</strong>
                    </th>
                    <th>
                      <strong>STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.id}</td>
                      <td>
                        <img
                          src={item.image ? item.image : profile}
                          width={70}
                          height={70}
                        />
                      </td>
                      <td>{item?.name?item?.name:item?.firstName +" " +item?.lastName}</td>
                      <td>{item?.email}</td>
                      <td>
                        {" "}
                        {item?.countryCode ? "+" + item?.countryCode : ""}
                        {item?.number}
                      </td>
                      {/* <td>{item?.userType}</td> */}
                      <td>{item.userType ? item.userType.charAt(0).toUpperCase() + item.userType.slice(1) : ''}</td>

                      <td>
                        {item?.isBlock || item?.isDeleted ? (
                          <Badge variant="danger light">Disabled</Badge>
                        ) : (
                          <Badge variant="success light">Enabled</Badge>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary light"
                            className="light sharp btn btn-primary i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item?.isBlock ? (
                              <Dropdown.Item
                                onClick={() => onAction(item?._id, false)}
                              >
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => onAction(item?._id, true)}
                              >
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
